<template>
  <div class="main">
    <div style="min-height: 500px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>消费记录</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="formBox">
        <div class="input">
          <span class="label">权益类型:</span>
          <el-select v-model="screenValue" placeholder="请选择">
            <el-option
              v-for="item in screenList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="timeInput">
          <span class="label">消费时间:</span>
          <el-date-picker
            v-model="screenTime"
            type="daterange"
            align="center"
            :clearable="false"
            range-separator="~"
            style="width: 260px"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="search-btn">
          <el-button type="primary" icon="el-icon-search" @click="onSubmit"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-left" @click="resetForm"
            >重置</el-button
          >
        </div>
      </div>

      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :current-page.sync="params.pageNo"
        style="width: 100%"
        :key="isKey"
      >
        <!-- <el-table-column align="center" type="selection" min-width="5%">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="vipEquityName"
          label="权益类型"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="value"
          label="消费类型"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="消费时间"
          min-width="25%"
        >
         <template slot-scope="scope">
            <span>{{
              scope.row.createTime.substring(
                0,
                scope.row.createTime.indexOf('.')
              )
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="num"
          label="消费记录"
          min-width="20%"
        >
        <template slot-scope="scope">
            <span v-if="scope.row.addSubtract == '1'"> <span style="color:green;">+</span> {{ scope.row.num }}次</span>
            <span v-else> <span style="color:red;">-</span> {{ scope.row.num }}次</span>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="remainingNum"
          label="剩余次数"
          min-width="25%"
        >
        <template slot-scope="scope">
            <span>{{ scope.row.remainingNum }}次</span>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin-top: 20px">
        <el-pagination
          :background="true"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  jobOwnerEquityLogs,
  jobVipEquity,
} from "@/api/personalCenter/personalCenter";
export default {
  data() {
    return {
      multipleSelection: "",
      total: 1,
      screenValue: "",
      screenTime: "",
      tableData: [],
      screenList: [],
      params: {
        pageNo: 1,
        pageSize: 10,
        userType: "1", //1个人,2企业;
        // type: "",
        beginTime: "",
        endTime: "",
        // userOrOrgName: "",
      },
      isKey: false,
    };
  },
  created() {
    this.getList();
    this.jobVipEquityList();
  },
  methods: {
    getList() {
       this.params.jobUserId = localStorage.getItem("userId");
      jobOwnerEquityLogs(this.params).then((res) => {
        console.log(res);
        this.tableData = res.data.rows;
        this.total = res.data.totalRows;
        if (res) {
          this.isKey = !this.isKey;
        }
      });
    },
    jobVipEquityList() {
      let params = {
        userType: "1",
      };
      jobVipEquity(params).then((res) => {
        console.log(res);
        res.data.map((item) => {
          let data = {};
          data.id = item.id;
          data.name = item.name;
          data.code = item.id;
          this.screenList.push(data);
          return item;
        });
        if (this.screenList[0].name != "不限") {
          this.screenList.unshift({
            name: "不限",
            code: "",
          });
        }
      });
    },
    resetForm() {

      this.screenValue = "";
      this.screenTime = "";
      this.params.jobVipEquityId = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.getList();
    },
    onSubmit() {
      this.params.jobVipEquityId = this.screenValue;
      if(this.screenTime && this.screenTime.length > 0 ){
        this.params.beginTime = this.screenTime[0] + " 00:00:00";
      this.params.endTime = this.screenTime[1] + " 23:59:59";
      }
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.pageNo = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.formBox {
  background-color: #fff;
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  .label {
    margin-right: 10px;
  }
}

// ::v-deep .el-form {
//   padding-top: 20px;
//   display: flex;
//   justify-content: space-around;
//   .el-form-item {
//     .el-form-item__content {
//       .el-input {
//         width: 100% !important;
//       }
//     }
//     .el-form-item__content:nth-child(1) {
//       width: 180px;
//     }
//     .el-form-item__content:nth-child(2) {
//       width: 146px;
//     }
//     .el-form-item__content:nth-child(3) {
//       width: 160px;
//     }
//   }
// }

::v-deep .el-tabs {
  // margin-top: 10px;
  background-color: #fff;
  .el-tabs__nav-wrap {
    background-color: #fafafa;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        border: none;
      }
      .is-active {
        background-color: #fff;
      }
    }
  }
}

.viewDetails {
  display: flex;
  justify-content: space-around;
  align-content: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
</style>
